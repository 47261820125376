import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { PUBLIC_SHOP_PAYMENT, ADEPT_DASHBOARD } from 'Consts/routes';
import { API_RESOURCE_REDIRECT_URI } from 'Consts/apiResources';
import { 
    OFFER_PERIOD_TYPE_SINGLE, 
    OFFER_PERIOD_TYPE_RECURRING, 
    OFFER_FIRST_PAYMENT_METHOD_TYPE_BOTH, 
    OFFER_FIRST_PAYMENT_METHOD_TYPE_RECURRING,
    OFFER_FIRST_PAYMENT_METHOD_TYPE_GATEWAY,
} from 'Consts/offers';
import { USER_OFFER_STATUS_NEW } from 'Consts/userOffers';

import { PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT } from 'Consts/paymentTransactions';

import Logger from 'Services/Logger';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import PaymentTypes from 'Components/public/shop/PaymentTypes';
import PaymentCheckout from 'Components/public/shop/PaymentCheckout';
import Spinner from 'Components/layout/Spinner';
import PaymentCheckoutPaymentButton from 'Components/public/shop/PaymentCheckoutPaymentButton';

export default class PublicShopUserOffer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
            payment: PropTypes.func.isRequired,
            adeptPayment: PropTypes.func.isRequired,
        }).isRequired,
        userOffer: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
        profile: PropTypes.object,
    };
    static defaultProps = {
        profile: null,
    };
    state = {
        isPending: false,
        paymentType: null,
        paymentMethod: null,
        promoCode: null,
        consentFitadeptTerms: false,
        consentMarketingEmail: false,
        consentMarketingPhone: false,
        consentSelectAll: false,
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { match } = this.props;
        const { params } = match;

        if (!prevState.consentSelectAll && this.state.consentSelectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState({
                consentFitadeptTerms: true,
                consentMarketingEmail: true,
                consentMarketingPhone: true,
            });
        }

        if (prevState.consentSelectAll && !this.state.consentSelectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState({
                consentFitadeptTerms: false,
                consentMarketingEmail: false,
                consentMarketingPhone: false,
            });
        }

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    onSubmit = () => {
        const { history, actions, userOffer, profile } = this.props;
        const { promoCode, paymentType, paymentMethod, consentFitadeptTerms, consentMarketingEmail, consentMarketingPhone } = this.state;

        if(!consentFitadeptTerms) {
            return toast.error('Zaznacz wymagane zgody');
        }

        this.setState({
            isPending: true,
            globalError: null,
        }, () => {
            let action = null;

            if (profile) {
                action = actions.adeptPayment({
                    id: userOffer.data.id,
                    transactionType: paymentType,
                    paymentMethodId: paymentMethod?.id,
                    continueUrl: process.env.APP_URL + withVariables(PUBLIC_SHOP_PAYMENT.path),
                    consents: [{
                        type: 'terms',
                        content: 'Przeczytałem i akceptuję regulamin sklepu',
                        value: consentFitadeptTerms,
                    }, {
                        type: 'marketingEmail',
                        content: 'Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną.',
                        value: consentMarketingEmail,
                    }, {
                        type: 'phone',
                        content: 'Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu.',
                        value: consentMarketingPhone,
                    }],
                });
            } else {
                action = actions.payment({
                    id: userOffer.data.id,
                    transactionType: paymentType,
                    paymentMethodType: paymentMethod?.type,
                    paymentMethodMaskedCard: paymentMethod?.maskedCard,
                    paymentMethodCardToken: paymentMethod?.value,
                    paymentMethodCardTokenType: paymentMethod?.tokenType,
                    continueUrl: process.env.APP_URL + withVariables(PUBLIC_SHOP_PAYMENT.path),
                    consents: [{
                        type: 'terms',
                        content: 'Przeczytałem i akceptuję regulamin sklepu',
                        value: consentFitadeptTerms,
                    }, {
                        type: 'marketingEmail',
                        content: 'Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną.',
                        value: consentMarketingEmail,
                    }, {
                        type: 'phone',
                        content: 'Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu.',
                        value: consentMarketingPhone,
                    }],
                });
            }

            action.then(response => {
                const data = response.payload;
                if (data[API_RESOURCE_REDIRECT_URI]) {
                    window.location.href = data[API_RESOURCE_REDIRECT_URI];
                } else {
                    toast('Zamówienie zostało utworzone');
                    history.push(ADEPT_DASHBOARD.path);
                }
            })
                .catch(error => {
                    toast('Nie udało się opłacić zamówienia. Zweryfikuj wprowadzone informacje i spróbuj ponownie');
                    Logger.error('[SHOP] User offer payment Error', { error, userOffer, promoCode, paymentMethod });
                    this.setState({
                        isPending: false,
                        errors: error.payload.validationErrors,
                        globalError: error.payload.message,
                    });
                });
        });
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { location, history, userOffer } = this.props;
        const { paymentType, paymentMethod, promoCode, isPending, consentFitadeptTerms, consentMarketingEmail, consentMarketingPhone, consentSelectAll } = this.state;
        const queryObject = parseQueryToObject(location.search);

        if(!userOffer || !userOffer.data) {
            return (<Spinner />);
        }

        let availablePaymentTypes = [];
        if(userOffer.data?.offer?.firstPaymentMethodType && userOffer.data?.status === USER_OFFER_STATUS_NEW) {
            switch (userOffer.data?.offer?.firstPaymentMethodType) {
                case OFFER_FIRST_PAYMENT_METHOD_TYPE_BOTH:
                    availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT];
                    break;
                case OFFER_FIRST_PAYMENT_METHOD_TYPE_RECURRING:
                    availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_DEFAULT];
                    break;
                case OFFER_FIRST_PAYMENT_METHOD_TYPE_GATEWAY:
                    availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_SINGLE];
                    break;
            }
        } else {
            if(userOffer.data?.offer?.periodType === OFFER_PERIOD_TYPE_SINGLE) {
                availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT];
            }
            if(userOffer.data?.offer?.periodType === OFFER_PERIOD_TYPE_RECURRING) {
                availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_DEFAULT];
            }
        }

        return (
            <StyledComponent
                className="public-shop-user-offer"
                styles={require('./styles')}
            >
                <Head title="Zamówienie - Sklep" />
                <LayoutContainer>
                    <div 
                        style={{ backgroundImage: `url(${require('Theme/images/shop/shop_offer.jpeg')})` }} 
                        className="block block-left"
                    />
                    <div className="block block-right">
                        {!userOffer?.data?.canBePaid ? (
                            <div className="cant-be-paid-wrapper">
                                <div className="cant-be-paid-title">
                                    To zamówienie jest już nieaktywne.<br />Skontaktuj się z FitAdept (bok@fitadept.com)
                                </div>
                            </div>
                        ) : (
                            <div className="content-container">
                                {queryObject.payment === 'failure' && (
                                    <div className="title-failure">
                                        Transakcja została odrzucona, prosimy spróbuj ponownie.
                                    </div>
                                )}
                                <PaymentCheckout 
                                    location={location}
                                    history={history}
                                    paymentType={paymentType}
                                    paymentMethod={paymentMethod}
                                    promoCode={promoCode}
                                    onAddPromoCode={promoCode => this.setState({ promoCode })}
                                    onSubmit={this.onSubmit}
                                    isPending={isPending}
                                    showPromoCodeForm={false}
                                    data={{
                                        periodType: userOffer.data.periodType,
                                        totalPriceGross: userOffer.data.totalPriceGross,
                                        totalDiscountedPriceGross: userOffer.data.totalPriceGross,
                                        durationValue: userOffer.data.durationValue,
                                        durationType: userOffer.data.durationType,
                                        noticePeriodDurationValue: userOffer.data.noticePeriodDurationValue,
                                        noticePeriodDurationType: userOffer.data.noticePeriodDurationType,
                                        offerName: userOffer?.data?.name,
                                        user: userOffer?.data?.user,
                                        lead: userOffer?.data?.lead,
                                        firstEvent: userOffer?.data?.firstEvent,
                                    }}
                                    showPaymentButton={false}
                                />
                                <PaymentTypes 
                                    location={location}
                                    history={history}
                                    userEmail={userOffer.data.user.email}
                                    availablePaymentTypes={availablePaymentTypes}
                                    paymentType={paymentType}
                                    paymentMethod={paymentMethod}
                                    onSelectPaymentType={paymentType => this.setState({ paymentType })}
                                    onSelectPaymentMethod={paymentMethod => this.setState({ paymentMethod })}
                                />
                                <PaymentCheckoutPaymentButton
                                    location={location}
                                    history={history}
                                    paymentType={paymentType}
                                    paymentMethod={paymentMethod}
                                    onSubmit={this.onSubmit}
                                    isPending={isPending}
                                    data={{
                                        offerId: userOffer.data.offer?.id,
                                        periodType: userOffer.data.periodType,
                                        totalPriceGross: userOffer.data.totalPriceGross,
                                        totalDiscountedPriceGross: userOffer.data.totalPriceGross,
                                    }}
                                    consentFitadeptTerms={consentFitadeptTerms}
                                    consentMarketingEmail={consentMarketingEmail}
                                    consentMarketingPhone={consentMarketingPhone}
                                    consentSelectAll={consentSelectAll}
                                    onChangeConsentFitadeptTerms={consentFitadeptTerms => this.setState({ consentFitadeptTerms })}
                                    onChangeConsentMarketingEmail={consentMarketingEmail => this.setState({ consentMarketingEmail })}
                                    onChangeConsentMarketingPhone={consentMarketingPhone => this.setState({ consentMarketingPhone })}
                                    onChangeConsentSelectAll={consentSelectAll => this.setState({ consentSelectAll })}
                                />
                            </div>
                        )}
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
