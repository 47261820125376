import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    background-color: ${variables.dpc_backgroundWhite};
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

     .layout-container {
        display: flex;
        max-width: 100%;
        margin: 0;
        width: 100% !important;
        height: 100%;
        flex-grow: 1;
        
        .block-left {
            position: relative;
            overflow: hidden;
            width: 42%;
            background-repeat: no-repeat;
            background-size: cover; 
            transition: width .3s;
        }

        .block-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 3em;
            margin-bottom: 3em;
            padding: 0 8em;
            width: 58%;

            .cant-be-paid-wrapper {
                .cant-be-paid-title {
                    font-size: 2em;
                    text-align: center;
                }
            }

            .content-container {
                margin-top: 0em;

                .title-failure {
                    font-size: 1.2em;
                    font-weight: 600;
                    color: #DA3143;
                }
            }
        }
    }

    @media (max-width: ${variables.desktopS}) {
        .layout-container {
            .block-right {
                padding: 0 3em;
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {
        .layout-container {
            position: relative;
            flex-direction: column;
            width: 100%;
            overflow: hidden;

            .block-left {
                position: absolute;
                height: 100%;
                min-height: -webkit-fill-available;
                min-height: -moz-available;
                width: 100%;
                z-index: 0;
            }

            .block-right {
                width: 100%;
                min-height: 100vh;
                z-index: 1;
                background: #000000cc;
                margin-top: 0;
                margin-bottom: 0;

                .cant-be-paid-wrapper {
                    .cant-be-paid-title {
                        color: white;
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.tabletS}) {
        .public-shop-payment-types {
            margin-top: 3em;
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .layout-container {
            .block-right {
                padding: 0 2em;
            }
        }
    }
`;

